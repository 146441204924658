<template>
  <div>
    <div class="union-product-main">
      <div class="union-product" v-for="item in products" :key="item" @click="toLink(item)">
        <div class="name">商品名称：{{item.name}}</div>
        <div class="time">下架时间：{{item.offtheShelfTime}}</div>
        <div class="stock">
          <span>价格：{{item.price}}元</span>
          <span>库存：{{item.stock}}</span>
        </div>
        <div class="right">
          <p>点击查看</p>
        </div>
      </div>
    </div>
    <div class="btn">
      <span @click="toStatistics()">查看统计</span>
    </div>
  </div>
</template>

<script>
import { inject, onMounted, reactive, toRefs } from 'vue'
export default {
  setup () {
    const post = inject('post')
    const Cookies = inject('Cookies')
    const useRouter = inject('useRouter')
    const state = reactive({
      storeId: Cookies.get('storeId'),
      applymentStateDesc: '',
      userId: Cookies.get('userId'),
      staffId: Cookies.get('staffId'),
      products: []
    })
    const init = () => {
      post('/product.list', {
        storeId: state.storeId,
        bizScope: 'ALLIANCE_CARD',
        pageNum: 1,
        pageSize: 100,
        sort: 'sort desc'
      }).then(res => {
        state.products = res.data.content
      })
    }
    const toLink = (item) => {
      window.location.href = window.location.origin + '/product/index.html?productId=' + item.productId + '&sourceStaffId=' + state.staffId + '&storeId=' + state.storeId
    }
    const toStatistics = () => {
      useRouter.push({
        name: 'statistics'
      })
    }
    onMounted(() => {
      init()
    })
    return {
      ...toRefs(state),
      toLink,
      toStatistics
    }
  }
}
</script>

<style scoped>
  .union-product-main{
    padding-bottom: 70px;
  }
  .union-product{
    background-color: #fff;
    padding: 12px 16px;
    position: relative;
    margin-bottom: 15px;
  }
  .union-product .name{
    font-size: 15px;
    font-weight: 500;
  }

  .union-product .time{
    font-size: 13px;
    color: #888;
    margin-top: 5px;
  }
  .union-product .stock{
    font-size: 13px;
    margin-top: 5px;
  }
  .union-product .stock span{
    margin-right: 15px;
  }
  .union-product .right{
    position: absolute;
    right: 15px;
    bottom: 20px;
    font-size: 13px;
    color: red
  }
  .btn{
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    box-sizing: border-box;
    padding: 10px 16px 16px 20px;
    background-color: #f8f8f8;
    z-index: 10;
  }

  .btn span{
    line-height: 44px;
    width: 100%;
    text-align: center;
    background-color: #1989fa;
    display: block;
    border-radius: 22px;
    color: #fff;
  }
</style>
